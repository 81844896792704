import { useEffect } from 'react';
import Cards from '../Cards/Cards';
import ProjectsCard from '../Damac/ProjectsCard';
import Faq from '../Faq/Faq';
import Header from '../Header/Header';
import Hills from '../Slider/Slider';
import Investment from '../Investment/Investment';
import RealEstate from '../RealEstate/RealEstate';
import Sari from '../Sari/Sari';
import Luma from '../Luma/Luma';
import Services from '../Services/Services';
import Form from '../Form/Form';
import Adhara from '../Adhara/Adhara';
import Habtoor from '../Habtoor/Habtoor';
import { data } from './../data';
import Slider from '../Slider/Slider';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <RealEstate />
      <Cards />
      <Investment />
      {data.map(({ title, document, link, image, type, id }) => (
        <Slider
          title={title}
          document={document}
          link={link}
          image={image}
          type={type}
          id={id}
          key={id}
        />
      ))}

      <Sari />
      <Luma />
      <Adhara />
      <Habtoor />

      <div className="show-cards">
        <ProjectsCard link="/damac" image="./img/projects-cards/1.jpg" title={'DAMAC Properties'} />
        <ProjectsCard link="" image="./img/projects-cards/2.png" title={'Iman Developers'} />
        <ProjectsCard link="" image="./img/projects-cards/3.jpg" title={'Oro24 Developments'} />
      </div>
      <Services />
      <Form />
      <Faq />
    </div>
  );
};
export default Home;
