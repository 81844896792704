import { useEffect } from 'react';
import Nav from './../Header/Nav/Nav';
import './../Cards/cards.sass';
import { data } from '../data';

const Page = () => {
  const image = data.filter(({ id }) => id === +window.location.search.substring(4))[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Nav />
      <div className="cards-slider-page">
        {[...Array(image.count).keys()].map((i) => (
          <img
            className="cards-slider-page-image"
            src={
              '../' +
              image.image.substring(0, image.image.length - 1) +
              (i < 9 ? '0' + (i + 1) : i + 1) +
              image.type
            }
            alt="img"
            key={i}
          />
        ))}
      </div>
    </>
  );
};

export default Page;
