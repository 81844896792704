export const data = [
  {
    id: 2,
    title: 'Jouri Hills',
    link: '/slider2',
    document: './documents/hills.pdf',
    image: 'img/cards/hills',
    type: '.png',
    count: 5,
  },
  {
    id: 8,
    title: 'Danube ( Bayz 101)',
    link: '/page',
    document: './documents/8.pdf',
    image: 'img/8/8_page-000',
    type: '.jpg',
    count: 36,
  },
  {
    id: 9,
    title: 'Aqua properties (The central Downtown )',
    link: '/page',
    document: './documents/9.pdf',
    image: 'img/9/9_pages-to-jpg-000',
    type: '.jpg',
    count: 28,
  },
  {
    id: 10,
    title: 'Ellington ( Claydon house)',
    link: '/page',
    document: './documents/10.pdf',
    image: 'img/10/10_pages-to-jpg-000',
    type: '.jpg',
    count: 82,
  },
  {
    id: 12,
    title: 'Damac ( Altitude)',
    link: '/page',
    document: './documents/12.pdf',
    image: 'img/12/12_page-000',
    type: '.jpg',
    count: 35,
  },
  {
    id: 13,
    title: 'Oro 24 ( Albero)',
    link: '/page',
    document: './documents/13.pdf',
    image: 'img/13/13_page-000',
    type: '.jpg',
    count: 38,
  },
  {
    id: 14,
    title: 'Samana ( Lake views)',
    link: '/page',
    document: './documents/14.pdf',
    image: 'img/14/14-сжатый_page-000',
    type: '.jpg',
    count: 32,
  },
  {
    id: 15,
    title: 'Imtiaz ( The cove)',
    link: '/page',
    document: './documents/15.pdf',
    image: 'img/15/15_compressed-сжатый_page-000',
    type: '.jpg',
    count: 73,
  },
  {
    id: 16,
    title: 'Eagle hills ( Rehan)',
    link: '/page',
    document: './documents/16.pdf',
    image: 'img/16/16_page-000',
    type: '.jpg',
    count: 13,
  },
];
