import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import { FreeMode, Thumbs, Pagination, Autoplay, Navigation } from 'swiper/modules';
import { HashLink as Link } from 'react-router-hash-link';

import 'swiper/css';
import './../Cards/cards.sass';
import style from './slider.module.sass';

const Slider = ({ title, document, image, type, link, id }) => {
  const { t } = useTranslation();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <div className="">
      <div className="cards wrap slider">
        <div className="title">{title}</div>
        <a href={document} className="card__link" target={'_blank'}>
          <img src="./img/cards/save.svg" alt="" />
          <span>{t('download')} PDF</span>
        </a>
        <div className="cards__wrap">
          <div className="photo">5 {t('photo')}</div>

          <Swiper
            slidesPerView={1}
            spaceBetween={24}
            navigation={{ prevEl: '.arrow-prev', nextEl: '.arrow-next' }}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[Autoplay, FreeMode, Navigation, Thumbs, Pagination]}
            pagination={true}
            autoplay={{ delay: 3500 }}
            breakpoints={{
              768: {
                pagination: false,
              },
            }}
            className="mySwiper2">
            {[1, 2, 3, 4, 5].map((item) => (
              <SwiperSlide key={item}>
                <div className="card">
                  <div className="card__image">
                    <Link to={link + (id ? '?id=' + id : '')}>
                      <img src={image + item + type} alt="" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className={'arrows ' + style.arrows}>
            <div className="arrow-prev">
              <svg
                width="60"
                height="59"
                viewBox="0 0 60 59"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="29.7999" cy="29.4258" r="29.4258" fill="#1F2E36" fillOpacity="0.5" />
                <path
                  d="M33.0686 39.052L23.2594 29.3041L33.0686 19.5562"
                  stroke="white"
                  strokeWidth="2.4523"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="arrow-next">
              <svg
                width="60"
                height="59"
                viewBox="0 0 60 59"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="30.1207" cy="29.4258" r="29.4258" fill="#1F2E36" fillOpacity="0.5" />
                <path
                  d="M26.8497 39.052L36.6589 29.3041L26.8497 19.5562"
                  stroke="white"
                  strokeWidth="2.4523"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className={style.thumbs__wrap}>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={16}
            slidesPerView={5}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className={style.thumbs}>
            {[1, 2, 3, 4, 5].map((item) => (
              <SwiperSlide key={item}>
                <div
                  className={style.thumb}
                  style={{ backgroundImage: `url(${image + item + type})` }}></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default Slider;
